import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";

const Header = (props) => {

  let navigate = useNavigate();

  const [IsNavCollapsed, setIsNavCollapsed] = useState(true);

  // const [ditem,setDItem]=useState(props.flag);

  const [ditem, setDItem] = useState(false);

  useEffect(() => {
    setDItem(props.type);
  }, [props.type]);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!IsNavCollapsed);
  };

  
  function logout() {
    if (window.confirm("Are you sure you want to logout?") == true) {
      props.logindata(false);
      // localStorage.clear();
      localStorage.removeItem('Userinfo');
      navigate("/");
    } else {
      return;
    }
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light text-white">
        <div className="container">
            <NavLink className="navbar-brand text-white" exact="true" to="/">
              <img src="/images/logo.png" style={{ height: "70px" }} />
            </NavLink>
          <button
            className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExample09"
            aria-controls="navbarsExample09"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarsExample09">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  exact="true"
                  aria-current="page"
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li className={ditem == false ? "d-none" : "nav-item"}>
                <NavLink
                  className="nav-link text-uppercase"
                  exact="true"
                  to="/videos"
                >
                  videos
                </NavLink>
              </li>
              {/* <li className={ditem == false ? "d-none" : "nav-item"}>
                <NavLink
                  className="nav-link"
                  exact="true"
                  to="/favourite"
                >
                  Favourite
                </NavLink>
              </li> */}
              <li className={ditem == false ? "d-none" : "nav-item"}>
                <NavLink className="nav-link" exact="true" to="/profile">
                  Profile
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact="true" to="/contactus">
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact="true" to="/aboutus">
                  About
                </NavLink>
              </li>
              <li className={ditem != false ? "d-none" : "nav-item"}>
                <NavLink className="nav-link" exact="true" to="/login">
                  Login
                </NavLink>
              </li>
              <li className={ditem == false ? "d-none" : "nav-item"}>
                <div className="nav-link c-pointer" onClick={logout}>
                  Logout
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
