import React from 'react'

const users = () => {
  return (
    <>
    <h1>Users</h1>
    </>
  )
}

export default users