import React from "react";

const footer = () => {
  return (
    <>
      <footer className="footer border-top py-3 bg-light" 
      // style={{position: "relative",bottom:"-25px"}}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="widget mb-5 mb-lg-0 mt-3">
                <div className="logo mb-2">
                  <img src="images/logo.png" alt="" className="img-fluid" />
                </div>
                <p className="d-none">
                  Tempora dolorem voluptatum nam vero assumenda voluptate,
                  facilis ad eos obcaecati tenetur veritatis eveniet distinctio
                  possimus.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-sm-6">

            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 mt-3">
              <div className="widget widget-contact mb-5 mb-lg-0">
                <h4 className="text-capitalize mb-3">Get in Touch</h4>
                <div className="footer-contact-block mb-4">
                    <span className="h6 mb-0">Support Available</span>
                  <h5 className="mt-2">
                    <a href="#">Pharmxplain@gmail.com</a>
                  </h5>
                  <h6>234-738-3202</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
