import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import database from "../firebase/firebase";
import { useNavigate } from "react-router-dom";

const Profile = (props) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [code_origin, setCodeOrigin] = useState("");

  var list = [];

  useEffect(() => {
    var currentuser = JSON.parse(localStorage.getItem("Userinfo"));

    database
      .collection("users")
      .get()
      .then((querySnapshot) => {
        var list = [];
        querySnapshot.forEach((element) => {
          var data = element.data();
          if (data.Email == currentuser.email) {
            list.push(data);
          }
        });
        var n = list[0].Name + "";
        setEmail(list[0].Email);
        setCode(list[0].Code);
        setCodeOrigin(list[0].Code_Origin)
        let fn = n.split(" ");
        setFname(fn[0]);
        setLname(fn[1]);
      });
  }, []);

  const submitData = (event) => {
    event.preventDefault();
    if (window.confirm("Are you sure you want to save changes") == true) {
      var currentuser = JSON.parse(localStorage.getItem("Userinfo"));
      const temporalQuery = database
        .collection("users")
        .doc(currentuser.nodeID);
      temporalQuery
        .update({
          Code: code,
          Email: email,
          Name: fname + " " + lname,
          Code_Origin:code_origin
        })
        .catch(
          localStorage.setItem(
            "Userinfo",
            JSON.stringify({
              email: email,
              nodeID: currentuser.nodeID,
              code: code,
              favlist:currentuser.favlist,
              watchlist:currentuser.watchlist
            })
          ),
          alert("Data saved successfully")
        );
    } else {
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8 col-sm-10 py-4">
            <h2>Profile</h2>
          </div>
          {/* <div className="col-4 col-sm-2" onClick={logout}>
            <h3  style={{ cursor: "pointer" }} className="mt-4">
              Logout
            </h3>
          </div> */}
        </div>
        <div className="row  my-5">
          <div className="col-sm-3 d-none">
            <div className="text-center">
              <img
                src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                className="avatar img-circle img-thumbnail"
                alt="avatar"
              />
              <h6 className="mt-1">Upload photo</h6>
              <input
                type="file"
                className="text-center center-block file-upload"
              />
            </div>
          </div>

          <div className="col-sm-12">
            <form onSubmit={submitData}>
              <div className="row">
                <div className="col-12 col-sm-6 mb-2">
                  <div className="form-group">
                    <h6>First Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      id="first_name"
                      placeholder="First Name"
                      title="Enter first name"
                      value={fname}
                      onChange={(e) => {
                        setFname(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 mb-2">
                  <div className="form-group">
                    <h6>Last Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      id="last_name"
                      placeholder="Last name"
                      title="Enter Last name"
                      value={lname}
                      onChange={(e) => {
                        setLname(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-6 mb-2">
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      disabled
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="you@email.com"
                      title="Enter your email."
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-6 mb-2">
                  <div className="form-group">
                    <h6>Access Code</h6>
                    <input
                      type="text"
                      className="form-control"
                      id="Access_Code"
                      placeholder="Access Code"
                      title="Acess Code"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-6 mb-2">
                  <div className="form-group">
                    <label>
                      <h6>Code Origin</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Code_Origin"
                      id="Code_Origin"
                      placeholder="Who gave you your code"
                      value={code_origin}
                      onChange={(e) => {
                        setCodeOrigin(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group text-end">
                    <br />
                    <button className="btn btn-lg btn-success" type="submit">
                      <i className="glyphicon glyphicon-ok-sign"></i> Save
                    </button>
                    {/* <button className="btn btn-lg mx-2" type="reset">
                      <i className="glyphicon glyphicon-repeat"></i> Reset
                    </button> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
