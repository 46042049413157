import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import db from "../firebase/firebase";
import firebase from "firebase/app";

const Login = (props) => {
  let nevigate = useNavigate();
  function checkBoxChange(e) {
    console.log(e.target.checked);
    setCheckBox(e.target.checked);
  }

  const [checkbox, setCheckBox] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const submitData = async (event) => {
    
    event.preventDefault();
    document.getElementById("btnlogin").disabled = true;
    document.getElementById("loginspinner").classList.remove("d-none");

    firebase
      .auth()
      .signInWithEmailAndPassword(email.trim(), password.trim())
      .then((userCredential) => {
        
        db.collection("users")
        .where("Email", "==", userCredential.user.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            
            if (checkbox) {
              localStorage.setItem(
                "logininfo",
                JSON.stringify({
                  mail: email,
                  pass: password,
                })
              );
            }
            localStorage.setItem(
              "Userinfo",
              JSON.stringify({
                email: userCredential.user.email,
                nodeID: userCredential.user.email,
                code: doc.data().Code,
                favlist:doc.data().favourite,
                watchlist:doc.data().watchlist,
              })
            );
            props.logindata(true);
            nevigate("/");
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

        // if (userCredential.user.emailVerified) {
          

        // }else{
        //   document.getElementById("btnlogin").disabled = false;
        //   document.getElementById("loginspinner").classList.add("d-none");
         
        // }

      })
      .catch((error) => {
        // var errorCode = error.code;
        // var errorMessage = error.message;
        document.getElementById("btnlogin").disabled = false;
        document.getElementById("loginspinner").classList.add("d-none");
        alert(error);
      });
  };

  useEffect(() => {
    // To store data
    // localStorage.setItem('Name', 'Rahul');

    // To retrieve data
    
    var Userdata = JSON.parse(localStorage.getItem("logininfo"));
    if (Userdata != undefined || Userdata != null) {
      setEmail(Userdata.mail);
      setPassword(Userdata.pass);
    } else {
    }

    // To clear a specific item
    // localStorage.removeItem('Name');

    // To clear the whole data stored in localStorage
    // localStorage.clear();
  }, []);

  return (
    <>
      <div className="container my-5 bg">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="wrap d-md-flex shadow row">
              <div className=" col-12 col-sm-6 rounded p-0">
                <img src="/images/loginpage.jpg" style={{height: "100%",width: "100%"}} />
                {/* <img src="/images/login.png" className="img-fluid h-100" /> */}
              </div>
              <div className="col-12 col-sm-6 p-4 p-md-5">
                <div className="d-flex">
                  <div className="w-100">
                    <h3 className="mb-4">Login</h3>
                  </div>
                </div>
                <form className="signin-form" onSubmit={submitData}>
                  <div className="form-group mb-3">
                    <label className="label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={handleEmailChange}
                      value={email}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="label">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      required
                      onChange={handlePasswordChange}
                      value={password}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      id="btnlogin"
                      className="c-btn btn form-control"
                    >
                      <span
                        id="loginspinner"
                        className="spinner-border spinner-border-sm mx-2 d-none"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Login
                    </button>
                  </div>
                  <div className="form-group d-md-flex mt-2 row">
                    <div className="w-50 text-left">
                      <label className="checkbox-wrap checkbox-primary mb-0">
                        <input
                          type="checkbox"
                          className="me-1"
                          onChange={checkBoxChange}
                        />
                        Remember Me
                      </label>
                    </div>
                    <div className="w-50 text-end">
                      <NavLink className="p-0" to="/forgotpassword">
                        Forgot Password
                      </NavLink>
                    </div>
                  </div>
                </form>
                <p className="text-center mt-5">
                  Need an account? &#160;
                  <NavLink className="p-0 d-inline" to="/signup">
                    Sign Up
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
