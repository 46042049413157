import { type } from '@testing-library/user-event/dist/type';
import React, { useEffect, useState } from 'react'

const Favourite = () => {
    useEffect(()=>{});
    const [type,setType]=useState("");
  return (
    <>

    </>
  )
}

export default Favourite