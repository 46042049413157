import React, { useState } from "react";
import firebase from "firebase/app";
import { useNavigate } from "react-router-dom";

require("firebase/auth");

function Forgotpassword() {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const submitform = (e) => {
    e.preventDefault();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert("Please check your email");
        navigate('/')
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
      });
  };
  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="wrap d-md-flex shadow row">
            <div className="col-12 col-sm-6 rounded">
              <img
                src="/images/fpasswordpage.jpg"
                style={{height: "100%",width: "100%"}}
              />
              {/* <img
                src="/images/forgotpassword.png"
                className="img-fluid h-100"
              /> */}
            </div>
            <div className="col-12 col-sm-6 p-4 p-md-5">
              <div className="d-flex">
                <div className="w-100">
                  <h3 className="mb-4">Forgot Password</h3>
                </div>
              </div>
              <form onSubmit={submitform} className="signin-form">
                <div className="form-group mb-3">
                  <label className="label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={handleEmailChange}
                        value={email}
                  />
                </div>
                <div className="form-group">
                  <button className="c-btn btn form-control" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgotpassword;
