import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAioFI5muFJbzMTjeY0sSXHwEBt_3ahZAM",
    authDomain: "pharmxplain.firebaseapp.com",
    projectId: "pharmxplain",
    storageBucket: "pharmxplain.appspot.com",
    messagingSenderId: "509236602910",
    appId: "1:509236602910:web:882e7dbefaabc643be660f",
    measurementId: "G-PJJX7SKTTH"
};
	
firebase.initializeApp(firebaseConfig);
        // var database = firebase.database();
        var database = firebase.firestore();
        

export default database;

