import React, { useState } from "react";
import db from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";

function Signup(props) {
  let navigate = useNavigate();
  const [change, setChange] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setC_Password] = useState("");
  const [code, setCode] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleCPasswordChange = (e) => {
    setC_Password(e.target.value);
  };
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

   function submitData(event) {
    event.preventDefault();
    if (!change) {
      setChange(!change);
      if (password != c_password) {
        setChange(change);
        alert("Your passwords don't match");
      }
      return;
    }
    document.getElementById("signupbtn").disabled = true;
    document.getElementById("signupspinner").classList.remove("d-none");
    firebase
      .auth()
      .createUserWithEmailAndPassword(email.trim(), password).then((e) => {
        console.log(e);
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        var data = {
          Name: name,
          Email: email,
          Code: code,
          register_date:
            new Date().getDate() +
            " " +
            months[new Date().getMonth()] +
            " " +
            new Date().getFullYear(),
          favourite: [],
          watchlist: [],
          Code_Origin: ""
        }

        db
          .collection("users")
          .doc(email)
          .set(data).then(() => {
            db.collection("users")
              .where("Email", "==", email)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  localStorage.setItem(
                    "Userinfo",
                    JSON.stringify({
                      email: doc.data().Email,
                      nodeID: doc.data().Email,
                      code: doc.data().Code,
                      favlist: doc.data().favourite,
                      watchlist: doc.data().watchlist,
                    })
                  );
                  props.logindata(true);
                  navigate("/videos");
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              })
          }, () => {
            alert("Email or Password is not valid")
          })
      });

      

  }

  return (
    <>
      <div className="container my-5 bg">
        <div className="row justify-content-center">
          <form className="signin-form" onSubmit={submitData}>
            {change == 0 ? (
              <div className="col-md-12">
                <div className="wrap d-md-flex shadow row">
                  <div className="col-12 col-sm-6 rounded p-0">
                    <img src="/images/signuppage.jpg" style={{height: "100%",width: "100%"}} />
                  </div>
                  <div className="col-12 col-sm-6 p-4 p-md-5">
                    <div className="d-flex">
                      <div className="w-100">
                        <h3 className="mb-4">Sign Up</h3>
                        <h5 className="mb-3 text-danger">*Make sure you have your access code before creating your profile* 
                        Send us a message using our "Contact Us" page to receive your access code</h5>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="label">Name</label>
                      <input
                        type="Name"
                        className="form-control"
                        placeholder="First and Last Name"
                        required
                        onChange={handleNameChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleEmailChange}
                        value={email}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label">Password (Must be 6 characters or longer)</label>
                      <input
                        type="Password"
                        className="form-control"
                        placeholder="Password"
                        required
                        onChange={handlePasswordChange}
                        value={password}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label">Confirm Password (Must be 6 characters or longer)</label>
                      <input
                        type="Password"
                        className="form-control"
                        placeholder="Confirm Password"
                        required
                        onChange={handleCPasswordChange}
                        value={c_password}
                      />
                    </div>
                    <button
                      className="form-group c-btn btn form-control"
                      // onClick={changeState}
                      type="submit"
                    >
                      Next {/* <NavLink className="">Next</NavLink> */}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12">
                <div className="wrap d-md-flex shadow row">
                  <div className="col-12 col-sm-6 rounded">
                    <img src="/images/signinpage2.jpeg" style={{height: "100%",width: "100%"}} />
                  </div>
                  <div className="col-12 col-sm-6 p-4 p-md-5">
                    <div className="d-flex">
                      <div className="w-100">
                        <h3 className="mb-4">Video Access Code</h3>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="label">Enter Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Code"
                        required
                        onChange={handleCodeChange}
                        value={code}
                      />
                    </div>
                    <div className="form-group mb-3 d-none">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum
                      </p>
                    </div>
                    <div className="form-group">
                      <button
                        id="signupbtn"
                        type="submit"
                        className="c-btn btn form-control"
                      >
                        <span
                          id="signupspinner"
                          className="spinner-border spinner-border-sm mx-2 d-none"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Verify & Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default Signup;
