import React from "react";

const Aboutus = () => {
  return (
    <>
      <div className="container-fliud bg-danger p-3">
        <div className="row">
          <div className="col-12 text-center text-white">
            <h2>About Us</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center text-white">
            <p className="h5 font-16" style={{ textAlign: "initial" }}>
              PharmXplain was founded by two pharmacy students at Idaho State
              University. Entering their final year of pharmacy school, they
              realized they both shared a passion for process improvement which
              lead them to rethink how to help patients better understand their
              medications. Currently, PharmXplain is focused on medications used
              to treat diabetes. We have plans to create videos for the top 300
              medications and beyond. Our pharmacist-approved work is
              patient-friendly and a meaningful supplement for helping patients
              understand their medications. The videos can be programmed with an
              interactive knowledge check so that patients can see how much
              information they are retaining.
            </p>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-sm-6">
          <div className="text-center">
          {/*  width: "45%" */}
            <img
              className="rounded img-thumbnail bg-dark border border-dark"
              style={{ height: "15rem" }}
              src="/images/aboutus1.jpg"
            />
            </div>
            <h4 className="my-2 d-none"> KC Roose</h4>
            <p className="h6 font-13 mt-3 text-white" style={{ wordSpacing: "2px" }}>
              KC Roose has focused on becoming a pharmacist since taking a
              pharmacy technician class as a high school senior. During the last
              seven years, he has enjoyed the opportunity to work in community,
              hospital, compounding, and clinical MTM pharmacy settings. Along
              the way, he discovered his passion for process improvement and
              creating efficiency for patients and healthcare workers. Beyond
              pharmacy, KC enjoys football, basketball, target shooting, and
              spending time with his wife and daughter.
            </p>
          </div>
          <div className="col-12 col-sm-6">
            <div className="text-center">
            <img
              className="rounded img-thumbnail bg-dark border border-dark"
              style={{ height: "15rem"}}
              src="/images/aboutus2.jpg"
            />
            </div>
            <h4 className="my-2 d-none">Jaret Koudelka</h4>
            <p className="h6 font-13 mt-3 text-white" style={{ wordSpacing: "2px" }}>
              Jaret Koudelka wanted to become a pharmacist since he was 5 years
              old. He grew up working in the community pharmacy where his dad
              worked as a pharmacist. He enjoys teaching patients about their
              medications and helping them avoid unnecessary expense and
              overprescribing. He is also very interested in lowering the cost
              of prescription drugs through innovative pharmacy practice models.
              Outside the pharmacy he enjoys weightlifting and cycling.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
