import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";


const ContactUs = () => {
  const navigate=useNavigate();
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const USER_ID = "fTNFQCa4RQXxR4aef";
  const SERVICE_ID = "service_4kccezj";
  const TEMPLATE_ID = "template_ve8fr9q";

  const submit = async (event) => {
    document.getElementById("btncontactus").disabled = true;
    document.getElementById("contactusspinner").classList.remove("d-none");
    event.preventDefault();
    emailjs
      .send(
        "service_4kccezj",
        "template_ve8fr9q",
        {
          from_name: Name,
          usre_message: Message,
          from_email: email,
          reply_to: email,
        },
        USER_ID
      )
      .then((result) => {
        document.getElementById("btncontactus").disabled = false;
        document.getElementById("contactusspinner").classList.add("d-none");
        alert("Sent email successfully");
        navigate("/")
      });
  };

  return (
    <>
      <div className="container my-5 bg">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="wrap d-md-flex shadow row">
              <div className="col-12 col-sm-6 rounded" style={{fontSize:"20px"}}>
                {/* <img src="/images/contact_us.png" className="img-fluid h-100" /> */}
                <div className="p-3 p-sm-5">
                  <h3>Ideal settings for our videos:</h3>
                  <ul>
                    <li>
                      <b><span className="text-danger"> Hospitals</span></b> discharging patients with new medications.
                    </li>
                    <li>
                      <b><span className="text-danger">Doctor’s offices</span></b> prescribing medications.
                    </li>
                    <li>
                      <b><span className="text-danger">Community pharmacies</span></b> before dispensing medications (patients can view prior to arriving at the pharmacy or when they arrive to pick up their prescription).
                    </li>
                    <li>
                      <b><span className="text-danger">Mail order pharmacies</span></b> requesting patients to view before and after their medication arrives.
                    </li>
                    <li>
                      <b><span className="text-danger">Insurance providers</span></b> and/or <b><span className="text-danger">MTM clinical pharmacies </span></b> integrating the videos on their apps to increase patient safety.
                    </li>
                    <li>And more (you tell us!)</li>
                  </ul>
                  <p>Contact us to see how our videos can help you!</p>
                </div>
              </div>
              <div className="col-12 col-sm-6 p-4 p-md-5">
                <div className="d-flex">
                  <div className="w-100">
                    <h3 className="mb-4">Contact Us</h3>
                  </div>
                </div>
                <form onSubmit={submit}>
                  <div className="form-group mb-3">
                    <label className="label">Name</label>
                    <input
                      type="Name"
                      name="from_name"
                      className="form-control"
                      placeholder="First and Last Name"
                      required
                      onChange={handleNameChange}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="from_email"
                      required
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="label">Message</label>
                    <textarea
                      required
                      name="user_message"
                      onChange={handleMessageChange}
                      rows={5}
                      placeholder="Message"
                      className="form-control"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <button
                      id="btncontactus"
                      className="c-btn btn form-control"
                      type="submit"
                    >
                      <span
                        id="contactusspinner"
                        className="spinner-border spinner-border-sm mx-2 d-none"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
