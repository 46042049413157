import React, { useEffect, useState } from "react";
import "./home.css";

const Home = () => {
  // useEffect(()=>{
  //   var vid = document.getElementById("video");
  //   vid.autoplay = true;
  //   vid.load();
  // },[])

  return (
    <>
      <div className="container my-4 text-center border bg-danger">
        <div className="row p-2 text-white">
          <h1>Why Choose Us</h1>
          <div className="text_sub h4 text-white">
            PharmXplain is committed to helping people understand
            <br /> their medications in an eye-catching and memorable way. We
            make 2D explainer videos connecting hearing AND seeing so that
            retention is feasible.
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <video
              id="video"
              className="rounded w-100"
              controls
              poster="/images/poster.png"
            >
              <source src="/images/VID-20220228-WA0000.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
