import "./App.css";
import Home from "./Home/home";
import Header from "./navbar/header";
import Users from "./users/users";
import Video from "./videos/videos"
import Login from "./forms/login";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Signup from "./forms/signup";
import Forgotpassword from "./forms/forgotpassword";
import Footer from './Home/footer'
import Profile from "./Home/profile";
import ContactUs from './Home/contactus';
import AboutUs from './Home/Aboutus';
import { useEffect, useState } from "react";
import Favourite from "./Home/Favourite";

function App() {
  const [loginState,setLoginState]=useState(false);
  

 function handleState(type) 
 {
    setLoginState(type)
 } 




 useEffect(()=>{
// To store data
// localStorage.setItem('Name', 'Rahul');

// To retrieve data
  var Userdata= localStorage.getItem('Userinfo');
  if (Userdata!=undefined) {
    setLoginState(true);
  } else {
    setLoginState(false);
  }

// To clear a specific item
// localStorage.removeItem('Name');

// To clear the whole data stored in localStorage
// localStorage.clear();
 },[])

  return (
    <>
      <Router>
      {loginState==true ? <Header logindata={handleState} type={loginState}/> : <Header type={loginState}/> }  
        <Routes>
         <Route exact="true" path="/" element={ <Home /> } />
        </Routes>
        <Routes>
          <Route exact="true" path="/users" element={<Users />} />
        </Routes>
        <Routes>
          <Route exact="true" path="/videos" element={<Video/>} />
        </Routes>
        <Routes>
          <Route exact="true" path="/Favourite" element={<Favourite/>} />
        </Routes>
        <Routes>
          <Route exact="true" path="/login" element={<Login logindata={handleState}/>} />
        </Routes>
        <Routes>
          <Route exact="true" path="/signup" element={<Signup logindata={handleState}/>} />
        </Routes>
        <Routes>
          <Route exact="true" path="/forgotpassword" element={<Forgotpassword/>} />
        </Routes>
        <Routes>
          <Route exact="true" path="/profile" element={<Profile logindata={handleState}/>} />
        </Routes>
        <Routes>
          <Route exact="true" path="/contactus" element={<ContactUs/>} />
        </Routes>
        <Routes>
          <Route exact="true" path="/aboutus" element={<AboutUs/>} />
        </Routes>
      </Router>
      <Footer/>
    </>
  );
}

export default App;
