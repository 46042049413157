import React, { useEffect, useState } from "react";
import Playvideo from "./playvideo";
import QuestionAnswer from "./questionandanswer";
import database from "../firebase/firebase";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";

const Videos = () => {
  let nevigate = useNavigate();
  const [type, settype] = useState(0);

  const [videoslist, setVideos] = useState([]);
  const [copy, setCopy] = useState([]);

  const [videolink, setVideolink] = useState("");

  const [questions, setQuestions] = useState([]);
  const [currentquestions, setCurrentQuestions] = useState([]);

  const [favorite, setFavorite] = useState([]);

  const [videotitle, setVideoTitle] = useState("");

  const [choice, setChoice] = React.useState("");

  // const [answer, setAnswer] = useState("");

  const [watchlist, setWatchList] = useState([]);

  const [watchid, setWatchId] = useState("");

  const [templist, setList] = useState([]);

  const [questionno, setQuestionNo] = useState(1);

  var list = [];
  useEffect(() => {
    var x = document.getElementById("loader");
    x.classList.remove("d-none");
    var currentuser = JSON.parse(localStorage.getItem("Userinfo"));
    setFavorite(currentuser.favlist);
    setWatchList(currentuser.watchlist);
    database
      .collection("videos")
      .get()
      .then((querySnapshot) => {
        var list = [];
        querySnapshot.forEach((element) => {
          var data = element.data();
          if (currentuser.code + "".includes(",")) {
            var temp = currentuser.code + "";
            var arr = temp.split(",");

            arr.forEach((element) => {
              if (data.allCodes.includes(element)) {
                list.push(data);
              }
            });
          } else {
            if (data.allCodes.includes(currentuser.code)) {
              list.push(data);
            }
          }
        });
        x.classList.add("d-none");
        
        sortVideosbyfavorite(list,currentuser.favlist);
        
        if (list.length <= 0) {
          document.getElementById("novideo").classList.remove("d-none");
          document.getElementById("search").classList.add("d-none");
        } 
        
        // else {
        //   document.getElementById("search").classList.remove("d-none");
        // }
      });

      

  }, []);

  function handleWatch(event) {
    
    var w = [];
    if (watchlist != null) {
      w = watchlist;
      if (!w.includes(event)) {
        w.push(event);
      }
    } else {
      w.push(event);
    }

    setWatchList(w);
    var currentuser = JSON.parse(localStorage.getItem("Userinfo"));
    const temporalQuery = database.collection("users").doc(currentuser.email);
    temporalQuery
      .update({
        watchlist: w,
      })
      .catch();
      localStorage.setItem(
        "Userinfo",
        JSON.stringify({
          email: currentuser.email,
          nodeID: currentuser.email,
          code: currentuser.code,
          favlist:currentuser.favlist,
          watchlist:w,
        })
      );
  }

  function handleChange(event) {
    var val = event.target.value;
    if (val) {
      var list = videoslist
        .filter((ele) => ele.title.toLowerCase().includes(val.toLowerCase()))
        .map((obj) => obj);
      setVideos(list);
    } else {
      setVideos(copy);
    }
  }
  function setFav(id, type) {
    // setFavorite(!favorite);
    
    var currentuser = JSON.parse(localStorage.getItem("Userinfo"));
    if (type) {
      var temp = favorite;
      // setFavorite(temp);
      temp = favorite?.filter(function (ele) {
        return ele !== id;
      });
      temp.push(id);
      setFavorite(temp);
      var e = temp;
      const temporalQuery = database.collection("users").doc(currentuser.email);
      temporalQuery
        .update({
          favourite: temp,
        })
        .catch();
        localStorage.setItem(
          "Userinfo",
          JSON.stringify({
            email: currentuser.email,
            nodeID: currentuser.email,
            code: currentuser.code,
            favlist:temp
          })
        );
        sortVideosbyfavorite(videoslist,temp);
    } else {
      if (window.confirm("Are you sure you want to unfavorite?") === true) {
        var temp = favorite;
        temp = favorite.filter(function (ele) {
          return ele !== id;
        });
        setFavorite(temp);
        const temporalQuery = database
          .collection("users")
          .doc(currentuser.email);
        temporalQuery
          .update({
            favourite: temp,
          })
          .catch();

          localStorage.setItem(
            "Userinfo",
            JSON.stringify({
              email: currentuser.email,
              nodeID: currentuser.email,
              code: currentuser.code,
              favlist:temp
            })
          );
          sortVideosbyfavorite(videoslist,temp);
      }
    }
  }

  const myCallback = () => {
    setQuestionNo(1);
    setChoice("");
    handleWatch(watchid);
    var questionlist = videoslist
      .filter((ele) => ele.videoURL.toLowerCase() === videolink.toLowerCase())
      .map((obj) => obj.questions);
    if (questionlist[0].length > 0) {
      setQuestions(questionlist[0]);
      var newlist = questionlist[0];
      setCurrentQuestions(newlist[0]);
      settype(2);
    } else {
      var x = document.getElementById("backbtn");
      x.classList.remove("d-none");
    }
  };

  const RadiohandleChange = (event) => {
    setChoice(event.target.value);
  };

  function checkArray(val) {
    var check = false;
    templist.forEach((element) => {
      if (element.index === val) {
        check = true;
      }
    });
    return check;
  }

  // function submitQuestion()
  // {
  //   
  //   var v=currentquestions;
  //   if (currentquestions.correctAnswer === choice)
  //   {
  //     setAnswer("Correct");
  //   }else{
  //     setAnswer("Incorrect your correct answer is "+currentquestions.correctAnswer);
  //   }
  // }

  function nextQuestion() {
    
    var index = questions.indexOf(currentquestions);
    if (choice === "") {
      alert("Please select the option");
      return;
    }

    if (currentquestions.correctAnswer === choice) {
      if (checkArray(index)) {
        var list = templist;
        list[index] = { index: index, result: true };
        setList(list);
      } else {
        var list = templist;
        list.push({ index: index, result: true });
        setList(list);
      }
    } else {
      if (checkArray(index)) {
        var list = templist;
        list[index] = { index: index, result: false };
        setList(list);
      } else {
        var list = templist;
        list.push({ index: index, result: false });
        setList(list);
      }
    }

    if (index + 1 === questions.length) {
      var count = 0;
      templist.forEach((element) => {
        if (element.result == true) {
          count++;
        }
      });

      alert("Your score is " + count + "/" + questions.length);

      var currentuser = JSON.parse(localStorage.getItem("Userinfo"));

     var data={"videoId":videotitle,"userId":currentuser.email,"score": count+"/"+questions.length}
        try {
          database.collection("questionScores").doc().set(data);
        } catch (error) {
          alert(error)
        }
    

      // nevigate("/videos")
      settype(0);
    }
    if (questions.length != index + 1) {
      index++;
      setQuestionNo(index + 1);
      setChoice("");
      setCurrentQuestions(questions[index]);
    }
  }

  function previousQuestion() {
    var index = questions.indexOf(currentquestions);
    if (index != 0) {
      setQuestionNo(index);
      index--;
      setChoice("");
      setCurrentQuestions(questions[index]);
    }
  }

  function sortVideosbyfavorite(videoslisttemp,fav)
  {
    
    var list=[];
    videoslisttemp.forEach(ele => {
      if (fav?.includes(ele.id)) 
      {
        list.push(ele);
      } else {
      }      
    });

    videoslisttemp.forEach(ele => {
      if (fav?.includes(ele.id)) 
      {
        
      } else {
        list.push(ele);
      }
      
    });



    setVideos(list);
    setCopy(list);
  }

  switch (type) {
    case 0:
      return (
        <div className="container-fluid py-3 bg">
          <div id="search" className="row">
            <div className="col-12 my-2 text-right">
              <input
                type="text"
                className="form-control w-25 float-end"
                onChange={handleChange}
                placeholder="Search"
              />
            </div>
          </div>
          <div className="row">
            <div id="loader" className="col-12 text-center d-none">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
            <div id="novideo" className="col-12 text-center d-none">
              <h5>No videos assigned to that access code. Please return to the profile page to enter a different access code.</h5>
            </div>
            {videoslist?.map((ele, index) => {
              return (
                <div className="col-12 col-sm-4 mb-3" key={index}>
                  <div
                    className={
                      watchlist?.includes(ele.id)
                        ? "card shadow p-3 border border-success lightgreen"
                        : "card shadow p-3"
                    }
                  >
                    <div className="row">
                      <div className="col-2">
                        <img
                          src="/images/play.png"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                      <div className="col-9 d-flex">
                        <b>Title: </b>
                        <div className="mx-1">{ele.title}</div>
                        <div
                          className=""
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "5px",
                          }}
                        >
                          {favorite?.includes(ele.id) ? (
                            <img
                              style={{ height: "15px" }}
                              onClick={() => {
                                setFav(ele.id, false);
                              }}
                              className="img-fluid"
                              src="/images/favorite.png"
                            />
                          ) : (
                            <img
                              onClick={() => {
                                setFav(ele.id, true);
                              }}
                              style={{ height: "15px" }}
                              className="img-fluid"
                              src="/images/unfavorite.png"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-2">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          settype(1);
                          setVideolink(ele.videoURL);
                          setVideoTitle(ele.title);
                          setWatchId(ele.id);
                        }}
                      >
                        Play
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
      break;
    case 1:
      return (
        <div
          className="container mt-4"
          style={{ height: "700px", width: "900px" }}
        >
          <div className="row">
            <div className="col-12 card shadow p-4">
              <div>
                <b>Title:</b> <span>{videotitle}</span>
                <br />
                <br />
              </div>
              <video
                className="rounded"
                controls
                disablePictureInPicture
                controlsList = "noplaybackrate nodownload"
                autoPlay
                onEnded={() => myCallback()}
              >
                <source src={videolink} type="video/mp4" />
              </video>
              <div id="backbtn" className="d-none">
                <button
                  onClick={() => {
                    settype(0);
                  }}
                  className="btn btn-primary m-1"
                >
                  Back
                </button>
                {/* <span>No question about this video</span> */}
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case 2:
      return (
        <div className="container mt-sm-5 my-5">
          <div className="question ml-sm-5 pl-sm-5 pt-2">
            <div className="py-2 h5">
              <b>
                Q {questionno}. {currentquestions.question}
              </b>
            </div>
            <div className="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3" id="options">
              <label className="options">
                <span className="h6"> {currentquestions?.option1}</span>
                <input
                  type="radio"
                  value={"option1"}
                  name="radio"
                  checked={choice === "option1"}
                  onChange={RadiohandleChange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="options">
                <span className="h6"> {currentquestions?.option2} </span>
                <input
                  type="radio"
                  value={"option2"}
                  name="radio"
                  checked={choice === "option2"}
                  onChange={RadiohandleChange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="options">
                <span className="h6">{currentquestions?.option3}</span>
                <input
                  type="radio"
                  name="radio"
                  value={"option3"}
                  checked={choice === "option3"}
                  onChange={RadiohandleChange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="options">
                <span className="h6">{currentquestions?.option4}</span>
                <input
                  type="radio"
                  name="radio"
                  value={"option4"}
                  checked={choice === "option4"}
                  onChange={RadiohandleChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          {/* <div>
              <span>Your answer is:</span>{' '}{answer}
            </div> */}
          <div className="d-flex align-items-center pt-3">
            <div id="prev">
              <button className="btn btn-primary" onClick={previousQuestion}>
                Previous
              </button>
              {/* <button className="btn btn-primary" onClick={submitQuestion}>
                Submit
              </button> */}
              <button className="btn btn-success mx-2" onClick={nextQuestion}>
                Next
              </button>
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }
};

export default Videos;
